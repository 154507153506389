import _ from 'lodash';
window._ = _;

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

import axios from 'axios';
import { useToast } from 'vue-toastification';
import mapboxgl from 'mapbox-gl';

window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

window.axios.defaults.withCredentials = true;

axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    const Toast = useToast();
    if(error.response) {
        if(error.response.status == 503) {
            Toast.warning('WieWaarWanneer wordt op dit moment geupdate. Probeer het later nog eens.');
        }else if(error.response.status == 401) {
            Toast.error('Niet ingelogd.');
        }else if(error.response.status == 419) {
            Toast.error('Sessie is verlopen. Log opnieuw in.');
        }else if(error.response.status == 403) {
            Toast.error('Je hebt geen rechten om deze actie uit te voeren.');
        }else if(error.response.status == 429) {
            Toast.error('Te veel verzoeken. Probeer het later nog eens.');
        }
        if(error.response.status == 503 || // Maintenance mode
            error.response.status == 401 || // Unauthenticated
            error.response.status == 419 || // CSRF token mismatch
            error.response.status == 403 // Forbidden
            ) {
                window.location.reload();
            }
    }
    return Promise.reject(error);
});

mapboxgl.accessToken = import.meta.env.VITE_MAPBOX_API_TOKEN;

window.mapboxgl = mapboxgl;

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allow your team to quickly build robust real-time web applications.
 */

import './echo';
